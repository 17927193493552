import { explorerUrl } from '@helpers/vivinoUrls';
import {
  CartIcon,
  CartsContext,
  allCartsUrl,
  cartUrl,
  getTotalBottles,
  getValidCarts,
  useI18N,
} from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import NavigationItem, {
  ALIGN_CONTAINER_RIGHT,
} from '../NavigationItem/NavigationItem.presentational';
import Cart from './Cart/Cart.presentational';
import styles from './cartMenu.module.scss';

const TRANSLATIONS = {
  noCarts: 'components.shared.navigation.cart.you_have_no_shopping_carts',
  explore: 'components.shared.navigation.cart.explore',
  showAllCarts: 'components.shared.navigation.cart.show_all_carts',
  youHaveCountShoppingCarts: 'components.shared.navigation.cart.you_have_count_shopping_carts',
  youHaveOneShoppingCart: 'components.shared.navigation.cart.you_have_one_shopping_cart',
};

export const CartMenuLabel = ({ totalBottles }) => (
  <span className={cx(styles.cartIcon)}>
    <CartIcon />
    {totalBottles > 0 && (
      <div className={styles.totalBottlesCount} data-testid="bottleCount">
        {totalBottles}
      </div>
    )}
  </span>
);

export const CheckoutCartMenuLabel = ({ totalBottles, cartId }) => (
  <a href={cartUrl({ cartId })} className={cx(styles.cartIcon)}>
    <CartIcon />

    {totalBottles > 0 && <div className={styles.totalBottlesCount}>{totalBottles}</div>}
  </a>
);

export const CartMenuContainer = ({ carts = [], isGhosting }) => {
  const { t } = useI18N();
  return carts.length > 0 ? (
    <ul className={styles.list} data-testid="cartList">
      <li className={cx(styles.item, styles.numberOfCartsItem)}>
        {carts.length > 1
          ? t(TRANSLATIONS.youHaveCountShoppingCarts, {
              count: carts.length,
            })
          : t(TRANSLATIONS.youHaveOneShoppingCart)}
      </li>
      {carts.slice(0, 2).map((cart) => (
        <li key={cart.id} className={cx(styles.item, styles.cart)}>
          <Cart cart={cart} isGhosting={isGhosting} />
        </li>
      ))}
      <li className={cx(styles.item, styles.allCartsLinkItem)}>
        <a href={allCartsUrl()} className={styles.allCartsLink}>
          {t(TRANSLATIONS.showAllCarts)}
        </a>
      </li>
    </ul>
  ) : (
    <ul className={cx(styles.list, styles.emptyCarts)}>
      <li className={cx(styles.item, styles.numberOfCartsItem)}>{t(TRANSLATIONS.noCarts)}</li>
      <li className={cx(styles.item, styles.exploreItem)}>
        <a href={explorerUrl({})} className={styles.exploreLink}>
          {t(TRANSLATIONS.explore)}
        </a>
      </li>
    </ul>
  );
};

const CartMenu = ({ visibleNavigationId, onMenuChanged, isGhosting, countryCode }) => {
  const { carts } = useContext(CartsContext);
  const [validCarts, setValidCarts] = useState([]);

  useEffect(() => {
    if (carts?.length > 0) {
      setValidCarts(getValidCarts(carts, countryCode));
    }
  }, [JSON.stringify(carts)]);

  return (
    <NavigationItem
      showCloseIcon
      testId="cartMenu"
      visibleNavigationId={visibleNavigationId}
      className={styles.navItem}
      onIsOpenChanged={onMenuChanged}
      align={ALIGN_CONTAINER_RIGHT}
      showChevron={false}
      label={<CartMenuLabel totalBottles={getTotalBottles(validCarts)} />}
      container={<CartMenuContainer carts={validCarts} isGhosting={isGhosting} />}
    />
  );
};

CartMenu.propTypes = {
  countryCode: PropTypes.string,
  isMobile: PropTypes.bool,
  visibleNavigationId: PropTypes.string,
  onMenuChanged: PropTypes.func,
  isGhosting: PropTypes.bool,
};

CartMenuContainer.propTypes = {
  carts: PropTypes.array,
  isGhosting: PropTypes.bool,
};

CartMenuLabel.propTypes = {
  totalBottles: PropTypes.number,
};

CheckoutCartMenuLabel.propTypes = {
  totalBottles: PropTypes.number,
  cartId: PropTypes.number,
};

export default CartMenu;
