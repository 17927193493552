import { getUrlSearchParams } from '@helpers/urlSearchParams';
import {
  CART_ITEM_SOURCE_TYPES,
  MIN_CHAR_SEARCH,
  SearchIcon,
  getExactMatchRedirectUrl,
  imagesUri,
  setCurrentCartItemSource,
  trackEvent,
  useI18N,
  useIsMounted,
  useThrottle,
} from '@vivino/js-web-common';
import React, { useEffect, useRef, useState } from 'react';

import SearchAdapter, { SearchResultWithType } from '@lib/api/search/SearchAdapter';

import NavigationItem from '../NavigationItem/NavigationItem.presentational';
import SearchResults from './SearchResults/SearchResults.presentational';
import styles from './searchBar.module.scss';

const TRANSLATIONS = {
  searchAnyWine: 'components.shared.navigation.search.search_any_wine',
  searchVivino: 'components.shared.navigation.search.search_vivino',
};

const KEY_CODE_UP = 38;
const KEY_CODE_DOWN = 40;
const KEY_CODE_TAB = 9;
const CHARACTER_LIMIT = 200;

interface onMenuChangedArgs {
  id: string | null;
}
interface SearchBarProps {
  isMobile: boolean;
  onMenuChanged: ({ id }: onMenuChangedArgs) => void;
  visibleNavigationId: string | null;
}

const SEARCH_BAR_NAVIGATION_ID = 'SEARCH_BAR_ID';
const SEARCH_EXPLORER_REDIRECT_EVENT = 'Search - Explorer Redirect';
const TRACKING_SHOW_SUGGESTION = 'Search - Search Suggestion - Show';
const TRACKING_SEARCH_QUERY = 'Search - Search Query - Action';

const EXECUTE_SEARCH_QUERY_DELAY = 400;

const SearchBar = ({ isMobile, visibleNavigationId, onMenuChanged }: SearchBarProps) => {
  const { t } = useI18N();

  const searchAdapter = useRef<SearchAdapter>();

  const resultsWrapperElement = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const urlSearchParams = getUrlSearchParams();
  const [query, setQuery] = useState(urlSearchParams.get('q') || '');
  const [throttledQuery] = useThrottle({
    value: query,
    delay: EXECUTE_SEARCH_QUERY_DELAY,
  });
  const [results, setResults] = useState<SearchResultWithType[]>([]);
  const [focusIndex, setFocusIndex] = useState(-1);
  const { isMounted } = useIsMounted();
  const isOpen = visibleNavigationId === SEARCH_BAR_NAVIGATION_ID;

  // only show search results if there are any and the query is long enough.
  const hasSearchResults = results?.length > 0;
  const hasMinLengthOfInput = query.length >= MIN_CHAR_SEARCH;
  const showSearchResults = hasSearchResults && hasMinLengthOfInput;
  const dropDownContent: SearchResultWithType[] = showSearchResults
    ? results
    : searchAdapter.current?.wineTypes;

  useEffect(() => {
    // For queries that are longer than the min search handle them throttled
    if (throttledQuery.length > MIN_CHAR_SEARCH) {
      executeSearchAndResetFocus(throttledQuery);
    }
  }, [throttledQuery]);

  useEffect(() => {
    searchAdapter.current = new SearchAdapter();

    document.addEventListener('keyup', handleKeyUp, true);

    return () => {
      document.removeEventListener('keyup', handleKeyUp, true);
    };
  }, []);

  useEffect(() => {
    if (isMounted) {
      focusIndex === -1 ? focusOnSearchInput() : focusOnSearchResultAtIndex(focusIndex);
    }
  }, [focusIndex]);

  const handleInputChange = (e) => {
    const newQuery = e.target.value;
    if (visibleNavigationId !== SEARCH_BAR_NAVIGATION_ID) {
      onMenuChanged({ id: SEARCH_BAR_NAVIGATION_ID });
    }

    // For queries that are the exact length of the minimum char fire them straight away
    if (newQuery.length === MIN_CHAR_SEARCH) {
      executeSearchAndResetFocus(newQuery);
    }
    setQuery(newQuery);
  };

  const attemptExploreRedirect = async () => {
    const possibleMatches = await searchAdapter.current?.execute(query, false);
    if (possibleMatches) {
      const redirectUrl = getExactMatchRedirectUrl(query, possibleMatches);
      if (redirectUrl) {
        trackEvent({
          event: SEARCH_EXPLORER_REDIRECT_EVENT,
          props: {
            query: query,
          },
        });
        setCurrentCartItemSource(CART_ITEM_SOURCE_TYPES.SEARCH_EXPLORE_REDIRECT);
        globalThis.location.assign(redirectUrl);
        return true;
      }
    }
    return false;
  };

  const executeSearchAndResetFocus = async (searchQuery) => {
    await executeSearch(searchQuery);
    resetFocusIndex();
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onMenuChanged({ id: null });
    }
  };

  const focusOnSearchResultAtIndex = (focusIndex) => {
    if (!dropDownContent.length) {
      onMenuChanged({ id: null });
    }

    const resultElements = resultsWrapperElement.current?.getElementsByTagName('a');

    if (resultElements?.length && resultElements.length > focusIndex) {
      resultElements[focusIndex].focus();
    }
  };

  const focusOnSearchInput = () => {
    searchInputRef.current?.focus();
  };

  const executeSearch = async (query) => {
    const searchResults = (await searchAdapter.current?.execute(query)) || [];

    const sentResultTypes = {};
    searchResults.forEach(({ resultType }) => {
      if (sentResultTypes[resultType]) {
        return;
      }

      //heap
      trackEvent({
        event: TRACKING_SHOW_SUGGESTION,
        props: {
          suggestion_category: resultType,
        },
      });

      sentResultTypes[resultType] = true;
    });
    setResults(searchResults);
  };

  const resetFocusIndex = () => {
    setFocusIndex(-1);
  };

  const updateFocusIndex = ({ closeOnLeave = false, reverse = false } = {}) => {
    if (!dropDownContent.length) {
      onMenuChanged({ id: null });
    }

    const nextFocusIndex = reverse ? focusIndex - 1 : focusIndex + 1;
    const boundedIndex = Math.max(-1, Math.min(dropDownContent.length - 1, nextFocusIndex));

    if (closeOnLeave && nextFocusIndex > boundedIndex) {
      onMenuChanged({ id: null });
    }

    setFocusIndex(boundedIndex);
  };

  const handleKeyDown = (e) => {
    const keyCode = e.keyCode;

    if (keyCode !== KEY_CODE_UP && keyCode !== KEY_CODE_DOWN && keyCode !== KEY_CODE_TAB) {
      return;
    }

    if (keyCode === KEY_CODE_UP || keyCode === KEY_CODE_DOWN) {
      e.preventDefault();
    }

    if (keyCode === KEY_CODE_DOWN || (keyCode === KEY_CODE_TAB && !e.shiftKey)) {
      updateFocusIndex({ closeOnLeave: keyCode === KEY_CODE_TAB });
    }

    if (keyCode === KEY_CODE_UP || (keyCode === KEY_CODE_TAB && e.shiftKey)) {
      updateFocusIndex({ reverse: true });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (typeof query === 'string' && query.trim().length > 0) {
      //heap
      trackEvent({
        event: TRACKING_SEARCH_QUERY,
        props: {
          query: query,
        },
      });

      if (query.length >= MIN_CHAR_SEARCH) {
        const isRedirected = await attemptExploreRedirect();
        if (isRedirected) {
          return;
        }
      }
      globalThis.location.assign(`/search?q=${encodeURIComponent(query)}`);
    }
  };

  const handleNavigationOpenChanged = ({ id }: { id: string }) => {
    if (visibleNavigationId === id) {
      resetFocusIndex();
    } else {
      // Only call on menu changed if the id has changed.
      onMenuChanged({ id });
    }
  };

  const renderMobileLabel = () => {
    return (
      <div
        className={styles.mobileSearchIcon}
        role="button"
        aria-label={t(TRANSLATIONS.searchVivino) as string}
      >
        <SearchIcon />
      </div>
    );
  };

  const renderMobileContainer = () => {
    return (
      <div className={styles.mobileSearchForm}>
        <form onSubmit={handleSubmit} className={styles.searchForm}>
          <input
            name="q"
            type="text"
            value={query}
            className={styles.searchInput}
            autoComplete="off"
            onChange={handleInputChange}
            maxLength={CHARACTER_LIMIT}
            aria-label={t(TRANSLATIONS.searchAnyWine) as string}
            autoFocus
            onSubmit={handleSubmit}
            style={{ backgroundImage: `url(${imagesUri('search.svg')})` }}
          />
        </form>
        {isOpen && <SearchResults query={query} results={dropDownContent} />}
      </div>
    );
  };

  const renderDesktopLabel = () => {
    return (
      <form onSubmit={handleSubmit} className={styles.searchForm}>
        <input
          type="text"
          maxLength={CHARACTER_LIMIT}
          name="q"
          className={styles.searchInput}
          placeholder={t(TRANSLATIONS.searchAnyWine) as string}
          autoComplete="off"
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          ref={searchInputRef}
          value={query || ''}
          aria-label={t(TRANSLATIONS.searchAnyWine) as string}
          style={{ backgroundImage: `url(${imagesUri('search.svg')})` }}
        />
      </form>
    );
  };

  const renderDesktopContainer = () => {
    return (
      isOpen && (
        <div ref={resultsWrapperElement} onKeyDown={handleKeyDown}>
          <SearchResults results={dropDownContent} query={query} />
        </div>
      )
    );
  };

  return (
    <div className={styles.search}>
      <NavigationItem
        showCloseIcon
        className={styles.navItem}
        showChevron={false}
        visibleNavigationId={visibleNavigationId}
        onIsOpenChanged={handleNavigationOpenChanged}
        label={isMobile ? renderMobileLabel() : renderDesktopLabel()}
        container={isMobile ? renderMobileContainer() : renderDesktopContainer()}
        closeOnMouseLeave={false}
        navigationId={SEARCH_BAR_NAVIGATION_ID}
      />
      {isOpen && <div className={styles.backdrop} />}
    </div>
  );
};

export default SearchBar;
