import { CloseWrapper, CrossIcon, useBreakpoint } from '@vivino/js-react-common-ui';
import {
  ArrowBorder,
  Chevron,
  ChevronDirections,
  contains,
  getRandom8HexDigits,
  useI18N,
} from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import styles from './navigationItem.module.scss';

export const ALIGN_CONTAINER_LEFT = 'left';
export const ALIGN_CONTAINER_RIGHT = 'right';
export const MARGIN = 24;
const COLOR_TEXT_COPY_LIGHT = '#989999';

const TRANSLATIONS = {
  back: 'components.shared.navigation_item.back',
};

const NavigationItem = ({
  align = ALIGN_CONTAINER_LEFT,
  container,
  closeOnMouseLeave = true,
  enableClickPopout = true,
  enableHoverPopout = false,
  label,
  onIsOpenChanged,
  showChevron = true,
  showCloseIcon = false,
  showContainerFullscreen = false,
  visibleNavigationId,
  navigationId,
  className,
  testId,
}) => {
  const { t } = useI18N();
  const [offset, setOffset] = useState(null);
  const idRef = useRef(navigationId || getRandom8HexDigits());
  const elementToggleRef = useRef();
  const elementContainerRef = useRef(null);
  const mountedRef = useRef(false);
  const prevVisibleNavigationIdRef = useRef(null);
  const { isMobile } = useBreakpoint();

  const update = ({ prevVisibleNavigationId, newVisibleNavigationId }) => {
    // check just opened
    const elementContainer = elementContainerRef.current;
    if (
      prevVisibleNavigationId !== newVisibleNavigationId &&
      newVisibleNavigationId === idRef?.current &&
      elementContainer
    ) {
      if (align === ALIGN_CONTAINER_LEFT) {
        const right = Math.ceil(elementContainer.getBoundingClientRect()?.right);

        const bodyWidth = Math.ceil(document.body.getBoundingClientRect()?.right);
        let newOffset = null;
        if (right > bodyWidth) {
          newOffset = right - bodyWidth + MARGIN;
        }

        if (newOffset) {
          // + 16 for a margin to the right
          setOffset(newOffset);
        }
      } else if (align === ALIGN_CONTAINER_RIGHT) {
        const left = Math.ceil(elementContainer.getBoundingClientRect()?.left);

        let newOffset = null;
        if (left < 0) {
          newOffset = Math.abs(left) + MARGIN;
        }

        if (newOffset) {
          // + 16 for a margin to the right
          setOffset(newOffset);
        }
      }
    }
  };

  // equivalent of componentDidUpdate
  // see https://dev.to/savagepixie/how-to-mimic-componentdidupdate-with-react-hooks-3j8c
  useEffect(() => {
    if (mountedRef.current) {
      update({
        prevVisibleNavigationId: prevVisibleNavigationIdRef.current,
        newVisibleNavigationId: visibleNavigationId,
      });
    } else {
      mountedRef.current = true;
    }
    prevVisibleNavigationIdRef.current = visibleNavigationId;
  }, [visibleNavigationId]);

  const handleClose = (e) => {
    const elementToggle = elementToggleRef.current;
    if (!contains(elementToggle, e.target)) {
      onIsOpenChanged({ id: null });
    }
  };

  const handleMouseEnter = () => {
    if (!enableHoverPopout) {
      return;
    }
    onIsOpenChanged({ id: idRef.current, event: 'mouseenter' });
  };

  const handleMainClick = () => {
    if (!enableClickPopout) {
      return;
    }
    onIsOpenChanged({ id: idRef.current, event: 'click' });
  };

  const handleFocus = () => {
    if (!enableClickPopout) {
      return;
    }
    onIsOpenChanged({ id: idRef.current, event: 'focus' });
  };

  const isOpen = idRef.current === visibleNavigationId;
  const isFullScreen = isMobile && isOpen && showContainerFullscreen;
  const shouldCloseIconBeShown = isOpen && isMobile && showCloseIcon;
  const shouldMobileMenuChevronBeShown = isMobile && showChevron;
  const shouldMenuChevronBeShown = !isMobile && showChevron;

  return (
    <div
      onMouseLeave={closeOnMouseLeave ? handleClose : null}
      data-testid={testId}
      className={cx(styles.navigationItem, {
        [styles.containerFullscreen]: isFullScreen,
      })}
    >
      <div
        onMouseEnter={handleMouseEnter}
        className={styles.labelContainer}
        onClick={handleMainClick}
        onFocus={handleFocus}
        ref={elementToggleRef}
      >
        {label}
        {shouldMenuChevronBeShown && (
          <Chevron
            className={styles.dropdownIcon}
            stroke={COLOR_TEXT_COPY_LIGHT}
            direction={ChevronDirections.DOWN}
            strokeWidth="2"
          />
        )}
        {shouldMobileMenuChevronBeShown && (
          <Chevron
            stroke={COLOR_TEXT_COPY_LIGHT}
            direction={ChevronDirections.RIGHT}
            strokeWidth="2"
          />
        )}
      </div>
      {isOpen && (
        <CloseWrapper onClose={handleClose}>
          <div className={styles.closeWrapper}>
            <div
              className={cx(styles.container, className, {
                [styles.alignContainerRight]: align === ALIGN_CONTAINER_RIGHT,
                [styles.alignContainerLeft]: align === ALIGN_CONTAINER_LEFT && !isMobile,
              })}
              style={
                offset
                  ? {
                      left:
                        isMobile && showContainerFullscreen
                          ? `-${MARGIN}px`
                          : align === ALIGN_CONTAINER_LEFT && `-${offset}px`,
                      right: align === ALIGN_CONTAINER_RIGHT && `-${offset}px`,
                    }
                  : null
              }
              ref={elementContainerRef}
            >
              {isFullScreen && (
                <div className={styles.back} onClick={handleMainClick}>
                  <Chevron
                    stroke={COLOR_TEXT_COPY_LIGHT}
                    direction={ChevronDirections.LEFT}
                    strokeWidth="2"
                  />{' '}
                  <div className={styles.backLink}>{t(TRANSLATIONS.back)}</div>
                </div>
              )}
              {shouldCloseIconBeShown && (
                <div onClick={handleClose} className={styles.closeIcon}>
                  <CrossIcon className={styles.closeIconCross} />
                </div>
              )}
              {container}
            </div>
            <div className={styles.arrowBorder}>
              <ArrowBorder />
            </div>
          </div>
        </CloseWrapper>
      )}
    </div>
  );
};

NavigationItem.propTypes = {
  align: PropTypes.oneOf([ALIGN_CONTAINER_LEFT, ALIGN_CONTAINER_RIGHT]),
  container: PropTypes.any,
  closeOnMouseLeave: PropTypes.bool,
  enableClickPopout: PropTypes.bool,
  enableHoverPopout: PropTypes.bool,
  label: PropTypes.any,
  onIsOpenChanged: PropTypes.func,
  showChevron: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  showContainerFullscreen: PropTypes.bool,
  visibleNavigationId: PropTypes.string,
  navigationId: PropTypes.string,
  className: PropTypes.string,
  testId: PropTypes.string,
};

export default NavigationItem;
