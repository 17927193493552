import { Button, ButtonTheme } from '@vivino/js-react-common-ui';
import {
  bottleLabel,
  cartUrl,
  getCartTypeDescription,
  merchantUrl,
  track,
  useI18N,
} from '@vivino/js-web-common';
import PropTypes from 'prop-types';
import React from 'react';

import Price from '@components/Price/Price';

import styles from './cart.module.scss';

const TRANSLATIONS = {
  shopMore: 'components.navigation.shared.navigation_carts_item.shop_more',
  goToCart: 'components.navigation.shared.navigation_carts_item.go_to_cart',
};

const getBackgroundImage = (vintage) => {
  const label = vintage?.image?.variations?.label_medium || vintage?.image?.variations?.label;

  return label
    ? {
        backgroundImage: `url(${label})`,
      }
    : {};
};

const CartItem = ({ cartItem }) => (
  <div className={styles.wine} data-testid="cartItem">
    <div className={styles.wineImage} style={getBackgroundImage(cartItem.vintage)} />
    <div className={styles.bottleCount}>
      {cartItem.bottle_count > 0 ? cartItem.bottle_count : ''}
    </div>
  </div>
);

const trackShopMoreClick = () => {
  track({
    screen: 'Cart Menu',
    event: 'Shop More Button - click',
  });
};

const Cart = ({ cart, isGhosting }) => {
  const { t } = useI18N();

  return (
    <div className={styles.cart}>
      <div className={styles.merchant}>{cart.merchant.name}</div>
      {isGhosting && (
        <div className={styles.cartType}>
          <span>{`Cart type: ${getCartTypeDescription()[cart.cart_type]}`}</span>
        </div>
      )}
      <div className={styles.information}>
        <div className={styles.quantity}>{bottleLabel({ count: cart.total_bottle_count })}</div>
        <Price amount={cart.subtotal_amount} currency={cart.currency} />
      </div>
      <div className={styles.wines}>
        {cart.items.map(
          (cartItem) => cartItem.valid && <CartItem key={cartItem.id} cartItem={cartItem} />
        )}
      </div>
      <div className={styles.actions}>
        <Button
          href={merchantUrl({ merchantId: cart.merchant.id })}
          onClick={trackShopMoreClick}
          className={styles.firstButton}
        >
          {t(TRANSLATIONS.shopMore)}
        </Button>
        <Button href={cartUrl({ cartId: cart.id })} theme={ButtonTheme.Accent}>
          {t(TRANSLATIONS.goToCart)}
        </Button>
      </div>
    </div>
  );
};

CartItem.propTypes = {
  cartItem: PropTypes.object,
};

Cart.propTypes = {
  isGhosting: PropTypes.bool,
  cart: PropTypes.object,
};

export default Cart;
