import React from 'react';

const PremiumLogo = () => (
  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.77782 2C5.77956 2 4.93416 2.7361 4.79683 3.72486L2.88976 17.4558C1.38174 28.3135 9.81585 38 20.7778 38C31.7398 38 40.1739 28.3135 38.6659 17.4558L36.7588 3.72486C36.6215 2.7361 35.7761 2 34.7778 2H6.77782Z"
      fill="#1E1E1E"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M13.4446 24.7868C13.4446 28.2796 16.276 31.111 19.7688 31.111V23.6398H22.1559C27.643 23.6398 30.7742 21.1287 30.7742 16.4786V16.3546C30.7742 11.6424 27.9531 8.94531 21.8769 8.94531H13.4446V24.7868ZM19.7688 19.2997V13.7195H21.6288C23.6129 13.7195 24.729 14.4945 24.729 16.3856V16.5096C24.729 18.3386 23.6439 19.2997 21.5978 19.2997H19.7688Z"
      fill="white"
    />
  </svg>
);

export default PremiumLogo;
