export default function generateQueryUrl({ baseUrl, params = [] }) {
  if (!params || params.length === 0) {
    return baseUrl;
  }

  let querifiedUrl = baseUrl.indexOf('?') >= 0 ? baseUrl : `${baseUrl}?`;
  querifiedUrl = querifiedUrl.endsWith('?') ? querifiedUrl : `${querifiedUrl}&`;

  params.forEach((param) => {
    if (Array.isArray(param.value)) {
      querifiedUrl +=
        param.value.length > 0 ? `${param.name}[]=${param.value.join(`&${param.name}[]=`)}&` : '';
    } else {
      querifiedUrl += param.value !== undefined ? `${param.name}=${param.value}&` : '';
    }
  });

  return querifiedUrl.endsWith('&')
    ? querifiedUrl.substr(0, querifiedUrl.length - 1)
    : querifiedUrl;
}
