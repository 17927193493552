import { createSignal, groupBy, useI18N } from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import MenuLink, { generateLinkUrl } from '@components/Navigation/MenuLink/MenuLink.presentational';

import ThumbnailAd from '../ThumbnailAd/ThumbnailAd.presentational';
import getSignalFromMenuItem from './getSignalFromMenuItem';
import styles from './subMenu.module.scss';

const SubMenu = ({ menu, className }) => {
  const { t } = useI18N();
  const menuItems = menu?.items;
  if (!menuItems?.length) {
    return null;
  }

  const { itemsWithSubItems, standaloneItems } = groupBy(menuItems, (item) =>
    // @ts-ignore
    item?.items?.length ? 'itemsWithSubItems' : 'standaloneItems'
  );

  const getKey = ({ title }, index) => [title, index].join('_');

  const ads = menu.ads?.map((ad, index) => (
    <ThumbnailAd
      key={getKey(ad, index)}
      className={styles.ad}
      href={generateLinkUrl(ad)}
      thumbnailType={ad.thumbnailType}
      text={t(ad.title, ad.titleParams)}
    />
  ));

  const getOnClickProp = (menuItem) => {
    const signal = getSignalFromMenuItem(menuItem);
    if (!signal) {
      return {};
    }

    return {
      onClick: () => createSignal(signal),
    };
  };

  return (
    <>
      <div className={cx(styles.subMenuSection, className)}>
        {itemsWithSubItems.map((element, index) => (
          <div key={getKey(element, index)} className={styles.section}>
            <MenuLink element={element} className={styles.menuLink} />
            {element?.items?.length && (
              <div className={styles.subsection}>
                {element.items.map((subElement, index) => (
                  <MenuLink
                    key={getKey(subElement, index)}
                    element={subElement}
                    {...getOnClickProp(subElement)}
                    className={styles.menuLink}
                  />
                ))}
              </div>
            )}
          </div>
        ))}
        {standaloneItems?.length > 0 && (
          <div className={styles.section}>
            {standaloneItems.map((element, index) => (
              <MenuLink
                key={getKey(element, index)}
                element={element}
                className={styles.menuLink}
              />
            ))}
          </div>
        )}
      </div>
      {ads && <div className={styles.adsSection}>{ads}</div>}
      {menu.itemShowAll && (
        <div className={styles.footerSection}>
          <MenuLink element={menu.itemShowAll} className={styles.menuSubCategoryHeadline} />
        </div>
      )}
    </>
  );
};

SubMenu.propTypes = {
  menu: PropTypes.object,
  className: PropTypes.string,
};

export default SubMenu;
