import React from 'react';

import styles from './simpleLabel.module.scss';

interface SimpleLabelProps {
  label: string;
  name: string;
}

const SimpleLabel = ({ label, name }: SimpleLabelProps) => (
  <div className={styles.selectedKey}>
    <span className={styles.label}>{label}</span>
    {name}
  </div>
);

export default SimpleLabel;
