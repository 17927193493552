import {
  GrapeFilter,
  Order,
  OrderBy,
  TopListFilter,
  TypedMerchantType,
} from 'vivino-js/api/explore';
import generateQueryUrl from '@helpers/generateQueryUrl';

export interface explorerUrlArgs {
  countryCode?: string;
  countryCodes?: string[];
  currencyCode?: string;
  foodIds?: string[] | number[];
  grapeFilter?: GrapeFilter;
  grapeIds?: string[] | number[];
  maxRating?: string | number;
  merchantId?: string | number;
  merchantType?: TypedMerchantType;
  minRating?: number | string;
  minRatingsCount?: number | string;
  order?: Order;
  orderBy?: OrderBy;
  page?: number | string;
  perPage?: number | string;
  priceRangeMax?: number | string;
  priceRangeMin?: number | string;
  regionIds?: string[] | number[];
  vcOnly?: boolean;
  topListFilter?: TopListFilter;
  wsaYear?: number | string;
  wineStyleIds?: string[] | number[];
  wineTypeIds?: string[] | number[];
  wineryIds?: string[] | number[];
  vintageIds?: string[] | number[];
  discountPrices?: boolean;
}

export const explorerUrl = ({
  countryCode,
  countryCodes = [],
  currencyCode,
  foodIds = [],
  grapeFilter,
  grapeIds = [],
  maxRating,
  merchantId,
  merchantType,
  minRating,
  minRatingsCount,
  order,
  orderBy,
  page = 1,
  perPage,
  priceRangeMax,
  priceRangeMin,
  regionIds = [],
  vcOnly,
  topListFilter,
  wsaYear,
  wineStyleIds = [],
  wineTypeIds = [],
  wineryIds = [],
  vintageIds = [],
  discountPrices = false,
}: explorerUrlArgs) => {
  const params = [
    { name: 'country_code', value: countryCode },
    { name: 'currency_code', value: currencyCode },
    { name: 'grape_filter', value: grapeFilter },
    { name: 'max_rating', value: maxRating },
    { name: 'min_rating', value: minRating },
    { name: 'order_by', value: orderBy },
    { name: 'order', value: order },
    { name: 'page', value: page },
    { name: 'per_page', value: perPage },
    { name: 'price_range_max', value: priceRangeMax },
    { name: 'price_range_min', value: priceRangeMin },
    { name: 'vc_only', value: vcOnly },
    { name: 'winery_ids', value: wineryIds },
    { name: 'vintage_ids', value: vintageIds },
    { name: 'wsa_year', value: wsaYear },
    { name: 'discount_prices', value: discountPrices },
    { name: 'min_ratings_count', value: minRatingsCount },
  ];

  if (merchantId) {
    params.push({ name: 'merchant_id', value: merchantId });
  }
  if (foodIds.length) {
    params.push({ name: 'food_ids', value: foodIds });
  }
  if (wineStyleIds.length) {
    params.push({ name: 'wine_style_ids', value: wineStyleIds });
  }
  if (grapeIds.length) {
    params.push({ name: 'grape_ids', value: grapeIds });
  }
  if (countryCodes.length) {
    params.push({ name: 'country_codes', value: countryCodes });
  }

  if (wineTypeIds.length) {
    params.push({ name: 'wine_type_ids', value: wineTypeIds });
  }
  if (regionIds.length) {
    params.push({ name: 'region_ids', value: regionIds });
  }
  if (topListFilter) {
    params.push({ name: 'top_list_filter', value: topListFilter });
  }

  if (merchantType) {
    params.push({ name: 'merchant_type', value: merchantType });
  }

  return generateQueryUrl({
    params,
    baseUrl: '/explore',
  });
};
