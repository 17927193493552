import {
  WineTypes,
  getPluralWineTypeName,
  getPluralWineTypeNameEn,
  getShortWineTypeName,
  getShortWineTypeNameEn,
  getSingularWineTypeNameEn,
  getWineTypeName,
  visibleWineTypeIds,
} from '@helpers/wine';
import {
  CountriesSortBy,
  GrapesSortBy,
  fetchCountries,
  fetchGrapes,
  fetchRegions,
  fetchWineStyles,
  replaceAccentedCharacters,
} from '@vivino/js-web-common';
import {
  BasicRegionStatistics,
  BasicWineType,
  BasicWinery,
  ExtendedCountry,
} from '@webtypes/goApi';
import { AnyCountry, AnyGrape, AnyRegion, AnyStyle } from '@webtypes/rubyLibApi';

import { fetchSponsoredWineries } from '../wineries';
import { AlgoliaResult } from './algoliaResult';

const ALGOLIA_APP_ID = '9TAKGWJUXL';
const ALGOLIA_KEY = '60c11b2f1068885161d95ca068d3a6ae';
const ALGOLIA_INDEX_NAME = 'WINES_prod';
export const WINES_COUNT_KEY = 'wines_count';
export const MIN_CHAR_SEARCH = 3;
const MAX_RESULTS_PER_CATEGORY = 3;

export enum SearchResultsTypes {
  SPONSORED_WINERY = 'RESULT_TYPE_SPONSORED_WINERY',
  GRAPE = 'RESULT_TYPE_GRAPE',
  COUNTRY = 'RESULT_TYPE_COUNTRY',
  WINE_STYLE = 'RESULT_TYPE_WINE_STYLE',
  WINE = 'RESULT_TYPE_WINE',
  REGION = 'RESULT_TYPE_REGION',
  WINE_TYPE = 'RESULT_TYPE_WINE_TYPE',
}

interface WineTypeRegExp {
  wineTypeId: number;
  regExp: RegExp;
}

export type SearchResult =
  | BasicWinery
  | AnyGrape
  | AnyCountry
  | AnyStyle
  | AnyRegion
  | Partial<BasicWineType>
  | AlgoliaResult;
export type SearchResultWithType = SearchResult & { resultType: SearchResultsTypes };

class SearchAdapter {
  algoliaIndex: any;

  wineTypeNamesRegExps: WineTypeRegExp[];

  wineTypes: SearchResultWithType[] = visibleWineTypeIds.map((wineTypeId: string) => {
    const id = parseInt(wineTypeId, 10);
    return {
      id,
      name: getShortWineTypeName(id) as string,
      resultType: SearchResultsTypes.WINE_TYPE,
    };
  });

  constructor() {
    import('algoliasearch').then((algoliasearch) => {
      const algoliaClient = algoliasearch.default(ALGOLIA_APP_ID, ALGOLIA_KEY);
      this.algoliaIndex = algoliaClient.initIndex(ALGOLIA_INDEX_NAME);
    });
    this.wineTypeNamesRegExps = undefined;
  }

  hasWines(item: SearchResult): boolean {
    return WINES_COUNT_KEY in item ? !!item[WINES_COUNT_KEY] : true;
  }

  _filter(
    query: string,
    items: SearchResult[] = [],
    maxResults: number = MAX_RESULTS_PER_CATEGORY
  ): SearchResult[] {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Using_special_characters
    const escapedForRegexp = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regExp = new RegExp(escapedForRegexp, 'gi');

    const matches: SearchResult[] = items.filter(
      (item: SearchResult) => regExp.test(item.name) && this.hasWines(item)
    );
    return matches.slice(0, maxResults);
  }

  async _searchWineries(query: string): Promise<BasicWinery[]> {
    const { wineries } = await fetchSponsoredWineries();

    return this._filter(query, wineries);
  }

  async _searchGrapes(query: string): Promise<AnyGrape[]> {
    const { grapes } = await fetchGrapes({ sortBy: GrapesSortBy.WINES_COUNT });
    return this._filter(query, grapes);
  }

  async _searchCountries(query: string): Promise<ExtendedCountry[]> {
    const { countries } = await fetchCountries({ sortBy: CountriesSortBy.WINES_COUNT });
    return this._filter(query, countries);
  }

  async _searchWineStyles(query: string): Promise<AnyStyle[]> {
    const { wine_styles } = await fetchWineStyles();
    return this._filter(query, wine_styles);
  }

  sortByWinesCountDescending(
    a: BasicRegionStatistics | ExtendedCountry,
    b: BasicRegionStatistics | ExtendedCountry
  ) {
    // Use wineries as tie-breaker when wines_count is equal
    if (a.wines_count === b.wines_count && a.wineries_count && b.wineries_count) {
      return b.wineries_count - a.wineries_count;
    }

    return b.wines_count - a.wines_count;
  }

  async _searchRegions(query: string): Promise<AnyRegion[]> {
    const { regions } = await fetchRegions(query);
    const sortedRegions = regions.sort((a, b) =>
      this.sortByWinesCountDescending(a.statistics, b.statistics)
    );
    return sortedRegions.slice(0, MAX_RESULTS_PER_CATEGORY);
  }

  async _searchAlgoliaIndex(query: string): Promise<AlgoliaResult[]> {
    const { hits } = await this.algoliaIndex.search(query, { hitsPerPage: 6 });
    return hits || [];
  }

  _getWineTypeNamesRegExps(): WineTypeRegExp[] {
    if (!this.wineTypeNamesRegExps) {
      this.wineTypeNamesRegExps = Object.values(WineTypes).reduce(
        (memo: WineTypeRegExp[], wineTypeId) => {
          // filtering by number ensures we only check the values of the enum, not the keys
          if (wineTypeId !== WineTypes.Unknown && typeof wineTypeId === 'number') {
            const namesForWineType = [
              replaceAccentedCharacters(getWineTypeName(wineTypeId) as string),
              replaceAccentedCharacters(getShortWineTypeName(wineTypeId) as string),
              replaceAccentedCharacters(getPluralWineTypeName(wineTypeId) as string),
              replaceAccentedCharacters(getSingularWineTypeNameEn(wineTypeId)),
              replaceAccentedCharacters(getShortWineTypeNameEn(wineTypeId)),
              replaceAccentedCharacters(getPluralWineTypeNameEn(wineTypeId)),
              // support matching partial name e.g. whi for white
              ...Array.from(
                replaceAccentedCharacters(getWineTypeName(wineTypeId) as string)
              ).reduce((r, _char, i, arr) => {
                r.push(arr.slice(0, i).join(''));
                return r;
              }, []),
            ];
            memo.push({ wineTypeId, regExp: new RegExp(`^(${namesForWineType.join('|')})$`, 'i') });
          }
          return memo;
        },
        []
      );
    }
    return this.wineTypeNamesRegExps;
  }

  _searchWineTypes(query: string): Partial<BasicWineType>[] {
    const normalizedQuery = replaceAccentedCharacters(query?.trim());
    const found = this._getWineTypeNamesRegExps().find(({ regExp }) => {
      return regExp.test(normalizedQuery);
    });
    if (found) {
      return [{ name: getShortWineTypeName(found.wineTypeId) as string, id: found.wineTypeId }];
    }
    return [];
  }

  async execute(queryText, includeWines = true): Promise<SearchResultWithType[]> {
    if (typeof queryText !== 'string') {
      return [];
    }

    if (queryText.length < MIN_CHAR_SEARCH) {
      return [];
    }

    const query = queryText.trim();

    if (query.length === 0) {
      return [];
    }

    const [
      wineriesResults,
      grapesResults,
      countriesResults,
      regionResults,
      wineStylesResults,
      wineTypeResults,
      algoliaResults,
    ] = await Promise.all([
      this._searchWineries(query),
      this._searchGrapes(query),
      this._searchCountries(query),
      this._searchRegions(query),
      this._searchWineStyles(query),
      this._searchWineTypes(query),
      includeWines ? this._searchAlgoliaIndex(query) : [],
    ]);

    return [
      ...wineriesResults.map((result) => ({
        ...result,
        resultType: SearchResultsTypes.SPONSORED_WINERY,
      })),
      ...grapesResults.map((result) => ({
        ...result,
        resultType: SearchResultsTypes.GRAPE,
      })),
      ...regionResults.map((result) => ({
        ...result,
        resultType: SearchResultsTypes.REGION,
      })),
      ...countriesResults.map((result) => ({
        ...result,
        resultType: SearchResultsTypes.COUNTRY,
      })),
      ...wineStylesResults.map((result) => ({
        ...result,
        resultType: SearchResultsTypes.WINE_STYLE,
      })),
      ...wineTypeResults.map((result) => ({
        ...result,
        resultType: SearchResultsTypes.WINE_TYPE,
      })),
      ...algoliaResults.map((result) => ({
        ...result,
        resultType: SearchResultsTypes.WINE,
      })),
    ];
  }
}

export default SearchAdapter;
