import { Badge } from '@vivino/js-react-common-ui';
import {
  Avatar,
  CUSTOM_USER_NOTIFICATION_EVENT,
  TRACKING_SCREENS,
  UserNotification,
  cellarsUrl,
  getAvatarUrl,
  getCurrentUser,
  getMerchandizingCouponItem,
  isSignedIn,
  loginUrlAfterLogout,
  managedWineriesUrl,
  merchantDashboardRootUrl,
  onLoginRequired,
  purchaseOrdersUrl,
  removeLocalCacheKey,
  removeMerchandizingCouponItem,
  settingsUrl,
  track,
  useI18N,
  userUrl,
  userWineUrl,
  wineryDashboardUrl,
} from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import PersonIcon from 'vivino-js/icons/Person';

import { csrfToken } from '@lib/api/authentication';

import NavigationItem, {
  ALIGN_CONTAINER_RIGHT,
} from '../NavigationItem/NavigationItem.presentational';
import styles from './userMenu.module.scss';

const TRANSLATIONS = {
  profile: 'components.shared.navigation.user_menu.profile',
  settings: 'components.shared.navigation.user_menu.settings',
  logOut: 'components.shared.navigation.user_menu.log_out',
  logIn: 'components.shared.navigation.user_menu.log_in',
  myWines: 'components.shared.navigation.user_menu.my_wines',
  orders: 'components.shared.navigation.user_menu.orders',
  managedWineries: 'components.shared.navigation.user_menu.managed_wineries',
  wineryDashboard: 'components.shared.navigation.user_menu.winery_dashboard',
  merchantDashboard: 'components.shared.navigation.user_menu.merchant_dashboard',
  cellar: 'components.shared.navigation.user_menu.cellar',
  premium: 'components.shared.navigation.user_menu.premium',
};

export const USER_NAVIGATION_ID = 'USER_NAVIGATION_ID';

const Container = ({ user }) => {
  const { t } = useI18N();

  const isUserPremium = user?.is_premium;

  const handleSignOut = () => {
    track({
      screen: TRACKING_SCREENS.NAVIGATION,
      event: 'UserMenu',
      props: {
        menuItem: 'signOut',
      },
    });

    removeLocalCacheKey();

    if (getMerchandizingCouponItem()) {
      removeMerchandizingCouponItem();
    }
  };

  return (
    <ul className={styles.list}>
      <li
        className={styles.item}
        // eslint-disable-next-line no-restricted-globals
        onClick={() => (globalThis.location.href = cellarsUrl)}
      >
        <div className={styles.cellarItem}>
          <a href={cellarsUrl} className={styles.itemLink}>
            {t(TRANSLATIONS.cellar)}
          </a>

          {!isUserPremium && (
            <Badge
              label={t(TRANSLATIONS.premium)}
              variant="outlined"
              size="medium"
              className={styles.premiumBadge}
            />
          )}
        </div>
      </li>
      <li className={styles.item}>
        <a href={userWineUrl({ user })} className={styles.itemLink}>
          {t(TRANSLATIONS.myWines)}
        </a>
      </li>
      <li className={styles.item}>
        <a href={purchaseOrdersUrl({ user })} className={styles.itemLink}>
          {t(TRANSLATIONS.orders)}
        </a>
      </li>
      <li className={styles.item}>
        <a href={userUrl({ user })} className={styles.itemLink}>
          {t(TRANSLATIONS.profile)}
        </a>
      </li>
      <li className={styles.item}>
        <a href={settingsUrl} className={styles.itemLink}>
          {t(TRANSLATIONS.settings)}
        </a>
      </li>
      {user.has_wineries && (
        <>
          <li className={styles.item}>
            <a href={managedWineriesUrl()} className={styles.itemLink}>
              {t(TRANSLATIONS.managedWineries)}
            </a>
          </li>
          <li className={styles.item}>
            <a href={wineryDashboardUrl()} className={styles.itemLink}>
              {t(TRANSLATIONS.wineryDashboard)}
            </a>
          </li>
        </>
      )}
      {user.has_merchants && (
        <li className={styles.item}>
          <a href={merchantDashboardRootUrl()} className={styles.itemLink}>
            {t(TRANSLATIONS.merchantDashboard)}
          </a>
        </li>
      )}
      <li className={cx(styles.item, styles.signOut)}>
        <form action={loginUrlAfterLogout()} method="post">
          <input type="hidden" name="_method" value="delete" />
          <input type="hidden" name="authenticity_token" value={csrfToken()} />
          <button
            data-test="signout-button"
            type="submit"
            onClick={handleSignOut}
            className={cx(styles.itemLink, styles.logoutButton)}
          >
            {t(TRANSLATIONS.logOut)}
          </button>
        </form>
      </li>
    </ul>
  );
};

const trackUserButtonClick = (isLoggedIn) => {
  track({
    screen: TRACKING_SCREENS.NAVIGATION,
    event: 'User Button - Click',
    props: {
      logged_in: isLoggedIn,
      modal_version: 2,
    },
  });
};

const UserMenu = ({
  visibleNavigationId,
  onMenuChanged,
  initialUserNotification = null,

  // mainly used for NextJS. If null, opens login dialog within the page
  // if URL is passed, redirects to a login page in that host
  loginHost,
}) => {
  const { t } = useI18N();
  const user = getCurrentUser();

  const [notification, setNotification] = useState(initialUserNotification);
  const [notificationText, setNotificationText] = useState('');

  function handleSetNotification(event) {
    setNotification(event?.detail?.notification);
    setNotificationText(event?.detail?.text);
  }
  useEffect(() => {
    globalThis.addEventListener(CUSTOM_USER_NOTIFICATION_EVENT, handleSetNotification, true);

    if (initialUserNotification) {
      const urlWithoutParameter = globalThis.location.href.replace(
        `user_notification_type=${initialUserNotification}`,
        ''
      );
      globalThis.history.replaceState(null, null, urlWithoutParameter);
    }

    return () => {
      globalThis.removeEventListener(CUSTOM_USER_NOTIFICATION_EVENT, handleSetNotification, true);
    };
  }, []);

  const handleMenuChanged = (args) => {
    onMenuChanged(args);

    if (args?.event === 'click') {
      trackUserButtonClick(true);
    }
  };

  const userNotification = (
    <UserNotification
      classNames={styles.notification}
      notification={notification}
      updateNotification={setNotification}
      text={notificationText}
    />
  );

  if (!isSignedIn()) {
    const handleAnonymousIconClick = () => {
      if (loginHost) {
        trackUserButtonClick(false);
        const redirectUrl = globalThis.location.href;
        globalThis.location.assign(
          `${loginHost}/login?redirect_url=${encodeURIComponent(redirectUrl)}`
        );
      } else {
        onLoginRequired();
        trackUserButtonClick(false);
      }
    };

    return (
      <div className={styles.loginLink}>
        <div onClick={handleAnonymousIconClick} role="button" aria-label={t(TRANSLATIONS.logIn)}>
          <PersonIcon />
        </div>
        {userNotification}
      </div>
    );
  }

  return (
    <span className={styles.container}>
      <NavigationItem
        visibleNavigationId={visibleNavigationId}
        onIsOpenChanged={handleMenuChanged}
        align={ALIGN_CONTAINER_RIGHT}
        label={<Avatar url={getAvatarUrl(user)} small />}
        container={<Container user={user} />}
        showChevron={false}
        showCloseIcon={false}
        testId="userMenu"
        navigationId={USER_NAVIGATION_ID}
      />
      {userNotification}
    </span>
  );
};

Container.propTypes = {
  user: PropTypes.object,
};

UserMenu.propTypes = {
  user: PropTypes.object,
  initialUserNotification: PropTypes.string,
  visibleNavigationId: PropTypes.string,
  onMenuChanged: PropTypes.func,
  loginHost: PropTypes.string,
};

export default UserMenu;
