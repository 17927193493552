import cx from 'classnames';
import React from 'react';

import styles from '../accessiBe.scss';

interface AccessiBeProps {
  className?: string;
  isEnabled?: boolean;
}

const AccessiBe = ({ className, isEnabled }: AccessiBeProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(styles.accessiBe, { [styles.enabled]: isEnabled }, className)}
  >
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z"
      fill="#F7F3F0"
      className={styles.path1}
    />
    <path
      d="M20.5242 16.5561C20.3195 16.5119 20.14 16.4451 19.9849 16.3576C19.7795 16.236 19.6218 16.1068 19.5112 15.9715C19.3996 15.835 19.3087 15.6854 19.2361 15.5179C18.8037 14.4994 18.2808 13.2153 17.6659 11.6645C17.0507 10.1136 16.2004 8.00409 15.1163 5.33333H13.4545C12.6732 7.21698 11.9602 8.93825 11.3158 10.5004C10.6703 12.0619 10.0269 13.6218 9.38471 15.1778C9.27961 15.4435 9.14825 15.6734 8.99024 15.8671C8.83224 16.0616 8.63993 16.2247 8.41333 16.3576C8.27977 16.4411 8.09877 16.5061 7.86925 16.552C7.63972 16.5991 7.43318 16.6283 7.25 16.6396V17.2866H12.0854V16.6396C11.4596 16.5841 11.0118 16.4944 10.7436 16.3689C10.4747 16.2452 10.3404 16.0934 10.3404 15.9171C10.3404 15.862 10.3499 15.7646 10.37 15.6259C10.3882 15.4876 10.4513 15.2632 10.5568 14.9534C10.64 14.7155 10.7381 14.4443 10.852 14.1389C10.9396 13.9054 11.0188 13.7007 11.0943 13.5124H15.3039L15.652 14.3586L16.2384 15.7843C16.2767 15.8773 16.3008 15.9529 16.3077 16.0069C16.3168 16.0623 16.3212 16.113 16.3212 16.1568C16.3212 16.2846 16.1194 16.3904 15.7148 16.4765C15.3104 16.5626 15.0886 16.6166 14.7893 16.6385V17.2862H21.0807V16.6393C20.9147 16.6283 20.7286 16.6013 20.5242 16.5561ZM14.7853 12.2542H11.5997L13.1812 8.24967L14.7853 12.2542Z"
      fill="#1E1E1E"
      className={styles.path2}
    />
    <path
      d="M12.6157 16.7484C12.4646 16.7152 12.3322 16.667 12.2176 16.6013C12.0665 16.5115 11.9505 16.417 11.868 16.3178C11.787 16.2174 11.7198 16.1061 11.6666 15.9835C11.3484 15.2333 10.9627 14.2871 10.5091 13.1438C10.057 12.0017 9.43005 10.4468 8.63054 8.47958H7.407C6.83191 9.86696 6.30571 11.1365 5.8306 12.2878C5.35513 13.4379 4.88038 14.5867 4.40746 15.7336C4.33047 15.9284 4.2334 16.0985 4.117 16.243C4.00059 16.386 3.85901 16.5057 3.69152 16.602C3.59336 16.6641 3.4598 16.7119 3.29012 16.7469C3.12007 16.7819 2.96827 16.802 2.83398 16.8115V17.2873H6.39621V16.8104C5.9357 16.7699 5.60545 16.7035 5.40804 16.6119C5.21099 16.5199 5.111 16.409 5.111 16.2777C5.111 16.2372 5.11867 16.1656 5.13327 16.0638C5.1464 15.9616 5.19238 15.7974 5.27047 15.5686C5.33141 15.3935 5.40366 15.1924 5.48796 14.968C5.50948 14.9103 5.6372 14.5859 5.81272 14.1418H8.6309L9.45741 16.1802C9.48588 16.2488 9.50266 16.3043 9.50923 16.3452C9.51543 16.3857 9.51908 16.4225 9.51908 16.4557C9.51908 16.5488 9.36911 16.628 9.07171 16.6915C8.77285 16.7546 8.51413 16.7947 8.29409 16.8115V17.2873H12.9007V16.8104C12.9003 16.81 12.7668 16.7819 12.6157 16.7484ZM6.06122 13.5124C6.56151 12.2491 7.20448 10.6285 7.20448 10.6285L8.37473 13.5124H6.06122Z"
      fill="#1E1E1E"
      className={styles.path3}
    />
  </svg>
);

export default AccessiBe;
