import AccessiBe from '@icons/AccessiBe/24/AccessiBe';
import { getUserAccessibilityEnabled, patchUserSession } from '@vivino/js-web-common';
import React from 'react';

import NavigationItem, {
  ALIGN_CONTAINER_RIGHT,
} from '../NavigationItem/NavigationItem.presentational';
import styles from './accessibilityToggleButton.module.scss';

// @TODO localize title attribute
const TITLE_ATTRIBUTE_TEXT = 'Enable/disable accessibility';

const handleOnClick = async () => {
  await patchUserSession({ accessibility_enabled: !getUserAccessibilityEnabled() });
  globalThis.location.reload();
};

const AccessiBeLabel = () => {
  return (
    <div title={TITLE_ATTRIBUTE_TEXT} className={styles.accessibilityToggleButton}>
      <AccessiBe className={styles.icon} isEnabled={getUserAccessibilityEnabled()} />
    </div>
  );
};

const AccessibilityToggleButton = () => {
  return (
    <NavigationItem
      showCloseIcon
      testId="accessibilityToggleButton"
      onIsOpenChanged={handleOnClick}
      align={ALIGN_CONTAINER_RIGHT}
      showChevron={false}
      label={<AccessiBeLabel />}
    />
  );
};

export default AccessibilityToggleButton;
