import { POSSIBLE_LANGUAGES } from '@helpers/countryHelpers';
import {
  TRACKING_SCREENS,
  resetLocalCacheKey,
  setLanguage,
  track,
  useI18N,
} from '@vivino/js-web-common';
import React from 'react';

import NavigationItem, {
  ALIGN_CONTAINER_RIGHT,
} from '../NavigationItem/NavigationItem.presentational';
import SimpleLabel from '../SimpleLabel/SimpleLabel.presentational';
import styles from './languageDropdown.module.scss';

const TRANSLATIONS = {
  language: 'components.shared.navigation.language',
};

interface LanguageDropdownContainerProps {
  languageList: { [language: string]: string };
}

export const LanguageDropdownContainer = ({ languageList }: LanguageDropdownContainerProps) => {
  function handleSelectNewLanguage(e) {
    e.preventDefault();
    const language = e.currentTarget.dataset.value;

    track({
      screen: TRACKING_SCREENS.NAVIGATION,
      event: 'Language Change',
      props: {
        language,
      },
    });

    setLanguage(language).then(() => {
      resetLocalCacheKey();
      globalThis.location.reload();
    });
  }

  return (
    <ul className={styles.list}>
      {Object.keys(languageList).map((language) => (
        <li key={languageList[language]} className={styles.item}>
          <a
            href=""
            className={styles.itemLink}
            data-value={language}
            onClick={handleSelectNewLanguage}
          >
            {languageList[language]}
          </a>
        </li>
      ))}
    </ul>
  );
};

interface LanguageDropdownProps {
  visibleNavigationId: string;
  onMenuChanged: ({ id }) => void;
}

const LanguageDropdown = ({ visibleNavigationId, onMenuChanged }: LanguageDropdownProps) => {
  const { t } = useI18N();
  return (
    <NavigationItem
      showCloseIcon
      className={styles.navItem}
      visibleNavigationId={visibleNavigationId}
      onIsOpenChanged={onMenuChanged}
      align={ALIGN_CONTAINER_RIGHT}
      label={
        <SimpleLabel
          label={t(TRANSLATIONS.language) as string}
          name={POSSIBLE_LANGUAGES[globalThis.__PRELOADED_LOCALE__]}
        />
      }
      container={<LanguageDropdownContainer languageList={POSSIBLE_LANGUAGES} />}
    />
  );
};

export default LanguageDropdown;
