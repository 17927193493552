import { WineryForm, getLocalCacheKey } from '@vivino/js-web-common';
import { BasicWinery, FullWinery } from '@webtypes/goApi';

import { apiGet, apiPut } from './apiClient';

interface UpdateWineryProps {
  wineryId: number;
  winery: WineryForm;
}

export const updateWinery = ({ wineryId, winery }: UpdateWineryProps): Promise<FullWinery> => {
  const url = `/api/wineries/${wineryId}`;

  return apiPut({
    url,
    body: {
      winery,
    },
  });
};

export function fetchSponsoredWineries(): Promise<{ wineries: BasicWinery[] }> {
  return apiGet({
    url: `/api/sponsored_wineries?cache_key=${globalThis.vivinoCacheKey}${getLocalCacheKey()}`,
  });
}
