import { SignalTypeNames } from '@vivino/js-web-common';

const SIGNAL_GETTERS_BY_NAME = {
  wine_style_ids: (queryParam) => ({
    type_name: SignalTypeNames.WineStyleLookup,
    value: queryParam.value[0],
    metadata: {},
  }),
  grape_ids: (queryParam) => ({
    type_name: SignalTypeNames.GrapeLookup,
    value: queryParam.value[0],
    metadata: {},
  }),
  region_ids: (queryParam) => ({
    type_name: SignalTypeNames.RegionLookup,
    value: queryParam.value[0],
    metadata: {},
  }),
};

const getSignalFromMenuItem = ({ queryParams }) => {
  const queryParam = (queryParams || []).find(({ name }) => SIGNAL_GETTERS_BY_NAME[name]);
  if (!queryParam) {
    return null;
  }

  const getSignal = SIGNAL_GETTERS_BY_NAME[queryParam.name];
  return getSignal(queryParam);
};

export default getSignalFromMenuItem;
