import { useBreakpoint } from '@vivino/js-react-common-ui';
import { vivinoPremiumLandingUrl } from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import AwardsMenu from '../AwardsMenu/AwardsMenu.presentational';
import MenuLink from '../MenuLink/MenuLink.presentational';
import NavigationItem from '../NavigationItem/NavigationItem.presentational';
import SubMenu from './SubMenu/SubMenu.presentational';
import styles from './wineNavigationMenu.module.scss';

const WineNavigationMenu = ({
  className,
  menuData = [],
  onMenuChanged,
  showChevron,
  visibleNavigationId,
  countryCode,
  showPremium,
}) => {
  const { isMobile } = useBreakpoint();

  const menuItemsToShow = menuData.filter(
    (menuItem) =>
      menuItem.title !== 'components.navigation.shared.navigation_premium.premium' || showPremium
  );

  return (
    <div className={cx(styles.menu, className)} data-testid="wineNavigationMenu">
      {menuItemsToShow.map((menu) => {
        if (menu.icon === 'premium') {
          menu.link = vivinoPremiumLandingUrl();
        }
        return (
          <NavigationItem
            showContainerFullscreen={Boolean(menu?.items.length)}
            key={menu.title}
            visibleNavigationId={visibleNavigationId}
            onIsOpenChanged={onMenuChanged}
            enableClickPopout={isMobile}
            enableHoverPopout={!isMobile}
            showChevron={showChevron}
            label={
              <MenuLink
                isLinkEnabled={!!menu.link || !isMobile}
                element={menu}
                className={cx(styles.menuLink)}
              />
            }
            container={<SubMenu menu={menu} className={styles.subMenu} />}
          />
        );
      })}
      <AwardsMenu countryCode={countryCode} />
    </div>
  );
};

WineNavigationMenu.propTypes = {
  className: PropTypes.string,
  menuData: PropTypes.array.isRequired,
  onMenuChanged: PropTypes.func,
  showChevron: PropTypes.bool,
  visibleNavigationId: PropTypes.string,
  countryCode: PropTypes.string,
  showPremium: PropTypes.bool,
};

export default WineNavigationMenu;
