import { SignalTypeNames, createSignals } from '@vivino/js-web-common';

import { SearchResultsTypes } from '@lib/api/search/SearchAdapter';

const SIGNAL_GETTERS_BY_RESULT_TYPE = {
  [SearchResultsTypes.WINE_STYLE]: (result) => ({
    type_name: SignalTypeNames.WineStyleLookup,
    value: result.id, // wine style ID
    metadata: {},
  }),
  [SearchResultsTypes.GRAPE]: (result) => ({
    type_name: SignalTypeNames.GrapeLookup,
    value: result.id, // grape ID
    metadata: {},
  }),
  [SearchResultsTypes.REGION]: (result) => ({
    type_name: SignalTypeNames.RegionLookup,
    value: result.id, // region ID
    metadata: {},
  }),
};

const getSendSignals = ({ groupedResults }) => {
  return () => {
    const signals = [
      SearchResultsTypes.WINE_STYLE,
      SearchResultsTypes.GRAPE,
      SearchResultsTypes.REGION,
    ].reduce((memo, resultType) => {
      const getSignal = SIGNAL_GETTERS_BY_RESULT_TYPE[resultType];
      const signalsForType = (groupedResults[resultType] || []).map(getSignal);
      return [...memo, ...signalsForType];
    }, []);
    return createSignals(signals);
  };
};

export default getSendSignals;
