import { getShipToCountries, getShippingStatesForCurrentCountry } from '@helpers/countryHelpers';
import {
  ShipToPropType,
  ShipToType,
  getCurrentShipToValue,
  handleSelectNewLocation,
  useI18N,
} from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import NavigationItem, {
  ALIGN_CONTAINER_RIGHT,
} from '../NavigationItem/NavigationItem.presentational';
import SimpleLabel from '../SimpleLabel/SimpleLabel.presentational';
import styles from './shipToDropdown.module.scss';

const TRANSLATIONS = {
  shippingToDifferentCountry: 'components.user.shipping.shipping_to_different_country',
  shipTo: 'components.shared.navigation.ship_to',
  state: 'components.shared.navigation.state',
};

export const Container = ({ shipToType }) => {
  const isShipToState = shipToType === ShipToType.State;
  const shipToOptions = isShipToState ? getShippingStatesForCurrentCountry() : getShipToCountries();

  return (
    <ul
      className={cx(styles.list, {
        [styles.longList]: shipToOptions.length > 30,
      })}
    >
      {shipToOptions.map((shipToOption) => {
        return (
          <li key={shipToOption.code} className={styles.item}>
            <a
              className={cx(styles.itemLink)}
              data-value={shipToOption.code}
              data-shiptotype={shipToType}
              onClick={handleSelectNewLocation}
            >
              {shipToOption.name}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

const ShipToDropdown = ({
  countryCode,
  stateCode,
  visibleNavigationId,
  onMenuChanged,
  shipToType = ShipToType.Country,
}) => {
  const { t } = useI18N();
  const isShipToState = shipToType === ShipToType.State;

  const shippingField = (
    <SimpleLabel
      label={isShipToState ? t(TRANSLATIONS.state) : t(TRANSLATIONS.shipTo)}
      name={getCurrentShipToValue(countryCode, stateCode, isShipToState)}
    />
  );

  if (!countryCode) {
    return null;
  }

  return (
    <NavigationItem
      showCloseIcon
      className={styles.navItem}
      align={ALIGN_CONTAINER_RIGHT}
      visibleNavigationId={visibleNavigationId}
      onIsOpenChanged={onMenuChanged}
      label={shippingField}
      container={<Container shipToType={shipToType} />}
    />
  );
};

ShipToDropdown.propTypes = {
  stateCode: PropTypes.string,
  countryCode: PropTypes.string,
  translations: PropTypes.object,
  visibleNavigationId: PropTypes.string,
  onMenuChanged: PropTypes.func,
  shipToType: ShipToPropType,
};

Container.propTypes = {
  shipToType: ShipToPropType,
};

export default ShipToDropdown;
