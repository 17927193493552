import { ChevronDown, getRandom8HexDigits } from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import AnimateHeight from 'react-animate-height';
import { CSSTransition } from 'react-transition-group';

import MenuLink from '../MenuLink/MenuLink.presentational';
import styles from './burgerMenuItem.module.scss';
import chevronTransitions from './chevronTransitions.module.scss';

export const BURGER_ITEM_ANIMATION_DURATION = 300;

const BurgerMenuSubItem = ({ item }) => {
  return (
    <div className={styles.burgerMenuSubItem}>
      {item.title && <MenuLink className={styles.itemText} element={item} />}
      {item?.items?.map((subItem, index) => (
        <MenuLink
          key={`${subItem} ${index}`}
          element={subItem}
          className={cx(styles.secondarySubItem, {
            [styles.hasItemTitle]: !!item.title || item?.noTitle,
          })}
        />
      ))}
    </div>
  );
};

const BurgerMenuItem = ({
  onIsOpenChanged,
  menuData,
  visibleNavigationId,
  isLinkEnabled = false,
}) => {
  const id = useRef(getRandom8HexDigits());
  const isOpen = id.current === visibleNavigationId;
  const burgerMenuItemRef = useRef(null);

  function handleMainClick() {
    onIsOpenChanged({
      id: isOpen ? null : id.current,
      ref: burgerMenuItemRef?.current,
    });
  }

  const hasMoreItems = menuData?.items?.length > 0;

  return (
    <div className={styles.burgerMenuItem}>
      <div className={styles.labelContainer} onClick={handleMainClick} ref={burgerMenuItemRef}>
        <MenuLink
          isLinkEnabled={isLinkEnabled}
          element={menuData}
          className={cx(styles.menuLink)}
        />
        {hasMoreItems && (
          <CSSTransition
            in={isOpen}
            timeout={BURGER_ITEM_ANIMATION_DURATION}
            classNames={{ ...chevronTransitions }}
          >
            <ChevronDown className={styles.chevron} />
          </CSSTransition>
        )}
      </div>
      <AnimateHeight duration={BURGER_ITEM_ANIMATION_DURATION} height={isOpen ? 'auto' : 0}>
        <div className={styles.subItemsContainer}>
          {menuData.items.map((item, index) => (
            <BurgerMenuSubItem item={item} key={`${item} ${index}`} />
          ))}
        </div>
      </AnimateHeight>
    </div>
  );
};

BurgerMenuSubItem.propTypes = {
  item: PropTypes.object,
};

BurgerMenuItem.propTypes = {
  menuData: PropTypes.object,
  label: PropTypes.node,
  onIsOpenChanged: PropTypes.func,
  showCloseIcon: PropTypes.bool,
  showContainerFullscreen: PropTypes.bool,
  visibleNavigationId: PropTypes.string,
  className: PropTypes.string,
  isLinkEnabled: PropTypes.bool,
};

export default BurgerMenuItem;
