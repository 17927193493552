import { DeferredImage } from '@vivino/js-react-common-ui';
import {
  CartItemSourceContext,
  getCartItemSource,
  getCartItemSourceProps,
  imagesUri,
} from '@vivino/js-web-common';
import cx from 'classnames';
import React, { useContext } from 'react';

import styles from './thumbnailAd.module.scss';

const THUMBNAIL_AD_IMAGE_PATHS = 'thumbnailAds/';

interface ThumbnailAdProps {
  className?: string;
  href?: string;
  text: string;
  thumbnailType: number;
}

const ThumbnailAd = ({ className, href, text, thumbnailType }: ThumbnailAdProps) => {
  const cartItemSourceType = useContext(CartItemSourceContext);
  const cartItemSourceParam = getCartItemSource(cartItemSourceType);

  const thumbnail55Url = `${THUMBNAIL_AD_IMAGE_PATHS}promo${thumbnailType}-55w.jpg`;
  const thumbnail110Url = `${THUMBNAIL_AD_IMAGE_PATHS}promo${thumbnailType}-110w.jpg`;

  if (!href || !text) {
    return null;
  }

  return (
    <a
      className={cx(styles.thumbnailAd, className)}
      href={href}
      {...getCartItemSourceProps(cartItemSourceParam)}
    >
      <DeferredImage
        src={imagesUri(thumbnail55Url)}
        srcSet={`${imagesUri(thumbnail55Url)}, ${imagesUri(thumbnail110Url)} 2x`}
      >
        <div className={styles.image} />
      </DeferredImage>
      <div className={styles.textSection}>
        <span className={styles.text}>{text}</span>
      </div>
    </a>
  );
};

export default ThumbnailAd;
