import { explorerUrl } from '@helpers/vivinoUrls';
import { WineGrapeIcon, WineRegionIcon, WineryIcon } from '@vivino/js-react-common-ui';
import {
  CART_ITEM_SOURCE_TYPES,
  CountryFlagIcon48,
  HighlightedText,
  SignalTypeNames,
  WineGlassIcon,
  createSignal,
  getCartItemSource,
  getCartItemSourceProps,
  groupBy,
  trackEvent,
  useI18N,
  wineUrl,
  wineryUrl,
} from '@vivino/js-web-common';
import {
  BasicCountry,
  BasicGrape,
  BasicRegion,
  BasicUserWineStyle,
  BasicWinery,
} from '@webtypes/goApi';
import { RubyLibFullWine } from '@webtypes/rubyLibApi';
import cx from 'classnames';
import React, { useEffect } from 'react';

import { SearchResultWithType, SearchResultsTypes } from '@lib/api/search/SearchAdapter';

import PartnerWineryBadge, {
  PartnerWineryBadgeSizes,
} from '@components/WineryPage/components/PartnerWineryBadge/PartnerWineryBadge';
import WineTypeIcon from '@components/wineStyles/WineTypeIcon/WineTypeIcon';

import getSendSignals from './getSendSignals';
import styles from './searchResults.module.scss';

const TRANSLATION_PREFIX = 'components.shared.navigation.search.results';
const TRANSLATIONS = {
  [SearchResultsTypes.GRAPE]: `${TRANSLATION_PREFIX}.shop_wines_by_grape`,
  [SearchResultsTypes.WINE_TYPE]: `${TRANSLATION_PREFIX}.shop_wines_by_type`,
  [SearchResultsTypes.COUNTRY]: `${TRANSLATION_PREFIX}.shop_wines_by_country`,
  [SearchResultsTypes.WINE_STYLE]: `${TRANSLATION_PREFIX}.shop_wines_by_style`,
  [SearchResultsTypes.REGION]: `${TRANSLATION_PREFIX}.shop_wines_by_region`,
  [SearchResultsTypes.SPONSORED_WINERY]: `${TRANSLATION_PREFIX}.discover_wineries`,
};
const TRACKING_CLICK_SUGGESTION = 'Search - Search Suggestion - Action';

const textSearchParam = getCartItemSource(CART_ITEM_SOURCE_TYPES.TEXT_SEARCH);
const searchExploreParam = getCartItemSource(CART_ITEM_SOURCE_TYPES.SEARCH_EXPLORE_SUGGESTIONS);
const searchExploreCartItemSourceProps = getCartItemSourceProps(searchExploreParam);

const EXPERIMENTAL_RESULTS_RENDER_CONFIG = [
  {
    resultType: SearchResultsTypes.WINE_TYPE,
    getAnchorProps: (result: BasicGrape) => ({
      href: explorerUrl({ wineTypeIds: [result.id] }),
      ...searchExploreCartItemSourceProps,
    }),
    getIcon: (props) => (
      <span className={styles.resultIcon}>
        <WineTypeIcon wineTypeId={props.id} />
      </span>
    ),
  },
  {
    resultType: SearchResultsTypes.GRAPE,
    getAnchorProps: (result: BasicGrape) => ({
      href: explorerUrl({ grapeIds: [result.id] }),
      ...searchExploreCartItemSourceProps,
    }),
    getIcon: () => (
      <span className={styles.resultIcon}>
        <WineGrapeIcon />
      </span>
    ),
  },
  {
    resultType: SearchResultsTypes.WINE_STYLE,
    getAnchorProps: (result: BasicUserWineStyle) => ({
      href: explorerUrl({ wineStyleIds: [result.id] }),
      ...searchExploreCartItemSourceProps,
    }),
    getIcon: () => (
      <span className={cx(styles.resultIcon, styles.wineStylesResultIcon)}>
        <WineGlassIcon />
      </span>
    ),
  },
  {
    resultType: SearchResultsTypes.REGION,
    getAnchorProps: (result: BasicRegion) => ({
      href: explorerUrl({ regionIds: [result.id] }),
      ...searchExploreCartItemSourceProps,
    }),
    getIcon: () => (
      <span className={styles.resultIcon}>
        <WineRegionIcon />
      </span>
    ),
  },
  {
    resultType: SearchResultsTypes.COUNTRY,
    getAnchorProps: (result: BasicCountry) => ({
      key: result.code,
      href: explorerUrl({ countryCodes: [result.code] }),
      ...searchExploreCartItemSourceProps,
    }),
    getIcon: (result: BasicCountry) => (
      <span className={styles.resultIcon}>
        <CountryFlagIcon48 countryCode={result.code} countryName={result.name} />
      </span>
    ),
  },
  {
    resultType: SearchResultsTypes.SPONSORED_WINERY,
    getAnchorProps: (result: BasicWinery) => ({
      href: wineryUrl({ winery: result, trailingParams: 'source=text_search_suggestion' }),
    }),
    getIcon: (result: BasicWinery) => {
      return result.image ? (
        <div
          className={styles.wineryImage}
          style={{
            backgroundImage: `url(${result.image?.variations?.small})`,
          }}
        ></div>
      ) : (
        <div className={styles.defaultLogoContainer}>
          <div className={styles.defaultlogo}>
            <WineryIcon width={16} height={16} />
          </div>
        </div>
      );
    },

    getTrailingContent: () => (
      <div className={styles.partnerBadge}>
        <PartnerWineryBadge size={PartnerWineryBadgeSizes.small} />
      </div>
    ),
  },
  {
    resultType: SearchResultsTypes.WINE,
    getAnchorProps: (result: RubyLibFullWine) => ({
      href: wineUrl({
        wine: result,
        trailingParams: 'ref=nav-search',
      }),
      onClick: () => {
        createSignal({
          type_name: SignalTypeNames.WineLookup,
          value: result.id, // wine ID
          metadata: {},
        });
      },
      ...getCartItemSourceProps(textSearchParam),
    }),
    getIcon: (result: RubyLibFullWine) => (
      <div
        className={styles.itemImage}
        style={
          result.image && {
            backgroundImage: `url(${result.image?.variations?.small_square})`,
          }
        }
      />
    ),
    getHighlightFullText: (result: RubyLibFullWine) =>
      result.vintages?.[result.vintages?.length - 1]?.name,
  },
];

type SearchResult = BasicCountry | RubyLibFullWine | BasicGrape | BasicUserWineStyle | BasicRegion;

interface AnyObject {
  [key: string]: any;
}

interface SearchResultsByTypeProps {
  results: SearchResult[];
  query: string;
  resultType: string;
  getAnchorProps: (result: SearchResult) => AnyObject;
  getIcon: ((result: SearchResult) => React.ReactNode) | (() => React.ReactNode);
  getHighlightFullText: (SearchResult) => string;
  getTrailingContent?: () => React.ReactNode;
  sendSignals: () => void;
}

const SearchResultsByType = ({
  results = [],
  query,
  resultType,
  getAnchorProps,
  getIcon,
  getHighlightFullText = (result) => result.name,
  getTrailingContent,
  sendSignals,
}: SearchResultsByTypeProps) => {
  const { t } = useI18N();
  if (!results.length) {
    return null;
  }

  const translationKey = TRANSLATIONS[resultType];

  const handleAnchorClick = (anchorProps: AnyObject = {}) => {
    anchorProps.onClick?.();
    sendSignals();

    //heap
    trackEvent({
      event: TRACKING_CLICK_SUGGESTION,
      props: {
        suggestion_category: resultType,
      },
    });
  };

  return (
    <div key={resultType} className={styles.resultList} data-testid={`searchResults-${resultType}`}>
      {translationKey && <h2 className={styles.resultListHeadline}>{t(translationKey)}</h2>}
      {results.map((result, index) => {
        const anchorProps = getAnchorProps(result);
        // somehow, sendSignals on unmount won't get called when clicking on links
        // so call it explicitly onClick
        const onAnchorClick = () => {
          handleAnchorClick(anchorProps);
        };

        let key;
        // Use id for objects with id,
        // code for objects with code, fall back on index(shouldn't happen)
        if ('id' in result) {
          key = result.id;
        } else {
          key = 'code' in result ? result.code : index;
        }

        return (
          <a
            key={key}
            {...anchorProps}
            onClick={onAnchorClick}
            className={styles.result}
            data-testid={`searchResult-${key}`}
          >
            {getIcon(result)}
            <HighlightedText
              fullText={getHighlightFullText(result)}
              highlight={query}
              highlightClassName={styles.highlight}
            />
            {getTrailingContent?.()}
          </a>
        );
      })}
    </div>
  );
};

interface SearchResultsProps {
  results: SearchResultWithType[];
  query: string;
}

const SearchResults = ({ results = [], query }: SearchResultsProps) => {
  const groupByResultType = (result) => result.resultType;
  const groupedResults = groupBy(results, groupByResultType);

  const sendSignals = getSendSignals({ groupedResults });

  // send signals on unmount
  // either when user blurs from search results suggest dropdown
  // or clicks on one of the links within the dropdown
  useEffect(() => {
    return () => {
      // We cannot return sendSignals directly since it returns a Promise<void>
      // by creating an anonymous function without async we can essentially achieve the same
      // by ignoring the promise.
      sendSignals();
    };
  }, []);

  return (
    <div className={styles.searchResults}>
      {EXPERIMENTAL_RESULTS_RENDER_CONFIG.map(
        ({ resultType, getAnchorProps, getIcon, getHighlightFullText, getTrailingContent }) =>
          SearchResultsByType({
            results: groupedResults[resultType],
            query,
            resultType,
            getAnchorProps,
            getIcon,
            getHighlightFullText,
            getTrailingContent,
            sendSignals,
          })
      )}
    </div>
  );
};

export default SearchResults;
