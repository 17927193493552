import { isVCCountry } from '@helpers/countryHelpers';
import { Anchor, allWineStyleAwardsUrl, useI18N } from '@vivino/js-web-common';
import React from 'react';

import styles from './AwardsMenu.module.scss';

const TRANSLATIONS = {
  awards: 'components.navigation.shared.awards.awards',
};

interface AwardsMenuProps {
  countryCode: string;
}

const AwardsMenu = ({ countryCode }: AwardsMenuProps) => {
  const { t } = useI18N();
  if (isVCCountry(countryCode)) {
    return null;
  }

  return (
    <Anchor href={allWineStyleAwardsUrl()} className={styles.awards} dataTestId="awardsMenu">
      <div className={styles.icon}></div>
      <div>{t(TRANSLATIONS.awards)}</div>
    </Anchor>
  );
};

export default AwardsMenu;
