import {
  WineGlassDessertFillIcon as Dessert,
  WineGlassFortifiedFillIcon as Fortified,
  WineGlassRedFillIcon as Red,
  WineGlassRoseFillIcon as Rose,
  WineGlassSparklingFillIcon as Sparkling,
  WineGlassWhiteFillIcon as White,
} from '@vivino/js-react-common-ui';
import { WineTypes } from '@vivino/js-web-common';
import React from 'react';

const Icons = {
  Red,
  White,
  Rose,
  Sparkling,
  Dessert,
  Fortified,
};

const getTypeById = (id: number): string => {
  const indexOfS = Object.values(WineTypes).indexOf(id);

  return Object.keys(WineTypes)[indexOfS];
};

interface WineTypeIconProps {
  wineTypeId: number;
}

const WineTypeIcon = ({ wineTypeId }: WineTypeIconProps) => {
  const type = getTypeById(wineTypeId);
  if (!type) {
    return null;
  }
  const Icon = Icons[type];

  return <Icon role="img" data-testid={`wineType${type}`} />;
};

export default WineTypeIcon;
